"use client"; // Ce fichier sera un composant client

import { useEffect } from "react";

export default function ClientInitializer() {
  useEffect(() => {
    if (typeof window !== "undefined" && window.WTN) {
      const { getPlayerId } = window.WTN.OneSignal;

      getPlayerId()
        .then((playerId: string) => {
          console.log("Player ID:", playerId);
        })
        .catch((error: any) => {
          console.error("Error fetching Player ID:", error);
        });
    }
  }, []);

  return null; // Pas d'affichage nécessaire
}