"use client";

import React, { useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { useSearchForm } from "../../(HeroSearchForm)/SearchFormContext";

export type TypeCategory = "Avion" | "Hélicoptère" | "Montgolfière" | "Sport & Aventure" | "";

const MobileStaySearchForm = () => {
  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests" | "category" | "duration">("location");
  const { category, setCategory, duration, setDuration } = useSearchForm();

  const categoryOptions: TypeCategory[] = ["Avion", "Hélicoptère", "Montgolfière", "Sport & Aventure"];
  const durationOptions = ["20 min", "30 min", "1h et plus"];

  const renderInputField = (
    label: string,
    value: string,
    onClick: () => void,
    active: boolean,
    children: React.ReactNode
  ) => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        active
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      {!active ? (
        <button
          className="w-full flex justify-between text-sm font-medium p-4"
          onClick={onClick}
        >
          <span className="text-neutral-400">{label}</span>
          <span>{value}</span>
        </button>
      ) : (
        children
      )}
    </div>
  );

  const renderCategoryInput = () => (
    <div className="flex flex-wrap gap-2 p-4">
      {categoryOptions.map((option) => (
        <button
          key={option}
          className={`py-1.5 px-4 rounded-full text-xs font-medium ${
            category === option
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setCategory(option);
            setFieldNameShow("duration");
          }}
        >
          {option}
        </button>
      ))}
    </div>
  );

  const renderDurationInput = () => (
    <div className="flex flex-wrap gap-2 p-4">
      {durationOptions.map((option) => (
        <button
          key={option}
          className={`py-1.5 px-4 rounded-full text-xs font-medium ${
            duration === option
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setDuration(option);
            setFieldNameShow("guests");
          }}
        >
          {option}
        </button>
      ))}
    </div>
  );

  return (
    <div className="space-y-4">
      {renderInputField(
        "Où",
        "À découvrir à proximité",
        () => setFieldNameShow("location"),
        fieldNameShow === "location",
        <LocationInput />
      )}
      {renderInputField(
        "Quand",
        "Ajouter des dates",
        () => setFieldNameShow("dates"),
        fieldNameShow === "dates",
        <DatesRangeInput selectsRange={false} href="/listing-product-map"/>
      )}
      {renderInputField("Catégorie", category, () => setFieldNameShow("category"), fieldNameShow === "category", renderCategoryInput())}
      {renderInputField("Durée", duration, () => setFieldNameShow("duration"), fieldNameShow === "duration", renderDurationInput())}
      {renderInputField(
        "Passagers",
        "Ajouter des Passagers",
        () => setFieldNameShow("guests"),
        fieldNameShow === "guests",
        <GuestsInput />
      )}
      
    </div>
  );
};

export default MobileStaySearchForm;