"use client";

import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useSearchForm } from "../(HeroSearchForm)/SearchFormContext";
// import ClearDataButton from "./ClearDataButton";

interface Props {
  className?: string;
  headingText?: string;
  desc?: string;
}

const LocationInput: FC<Props> = ({
  className = "",
  headingText = "À découvrir à proximité",
  desc = "Saisissez votre adresse"
}) => {
  const { location, setLocation, setLat, setLng } = useSearchForm();
  const [value, setValue] = useState(location);
  const [showPopover, setShowPopover] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(location);
  }, [location]);

  useEffect(() => {
    if (value.length > 1) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchSuggestions = async (input: string) => {
    try {
      const response = await fetch(`https://esi-fly.com/cors.php?url=https://api.wingly.io/api/v1/addresses/autocomplete?input=${encodeURIComponent(input)}`);
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSelectLocation = async (item: any) => {
    setValue(item.name);
    setShowPopover(false);
    setLocation(item.name);
    try {
      const response = await fetch(`https://esi-fly.com/cors.php?url=https://api.wingly.io/api/v1/addresses/geocode?place_id=${item.place_id}`);
      const data = await response.json();
      if (data.lat && data.lng) {
        setLat(data.lat);
        setLng(data.lng);
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const renderSearchValues = () => {
    return (
      <div className="mt-3">
        {suggestions.map((item) => (
          <div
            className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700"
            onClick={() => handleSelectLocation(item)}
            key={item.place_id}
          >
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={desc}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              setShowPopover(true);
            }}
            ref={inputRef}
            onFocus={() => setShowPopover(true)}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
          {/* {value && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                setLocation("");
                setLat(null);
                setLng(null);
              }}
            />
          )} */}
        </div>
        {showPopover && (
          <div className="mt-7">
            {value ? renderSearchValues() : (
              <div className="py-2 mb-1 flex items-center space-x-3 text-sm text-neutral-500 dark:text-neutral-400">
                <span>Commencez à taper pour voir les suggestions</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationInput;